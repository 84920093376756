<template>
  <v-container>
    <progress-circular />
    <v-row v-if="
    !loading
    && isNullArray(videos.data) == false
    && isNullArray(articles.data) == false
    && isNullArray(posts.data) == false">
      <base-main-title class="mt-12" :title="'الفديوهات'" />
      
      <video-card v-for="(video, i) in videos.data.slice(0, 6)" :key="'video' + i" :size="3" :value="video" />
      <base-router-link :title="'عرض جميع الفديوهات'" :to="'videos'" />

      <!-- <base-main-title class="mt-0" :title="'المقالات'" />
      <article-card v-for="(article, i) in articles.data.slice(0, 6)" :key="'article' + i" :size="layout[i]"
        :value="article" />
      <base-router-link :title="'عرض جميع المقالات'" :to="'articles'" /> -->

      <base-main-title :title="'المنشورات'" />
      <post-card v-for="(post, i) in posts.data.slice(0, 8)" :key="'post' + i" :size="4" :value="post" />
      <base-router-link :title="'عرض جميع المنشورات'" :to="'posts'" /> 
     <v-col>

      </v-col>
      <!-- <div class="container">
    <StackGrid
      :columnWidth="400"
      :gutterX="20"
      :gutterY="20">
       
        <div class="stack-item"
          v-for="(item, i) in articles.data" :key="i"
        >
        <article-card :key="'article' + i" :size="3" :value="item" />
        </div>
    </StackGrid>
  </div> -->
    </v-row>
  </v-container>

</template>

<script>
// Utilities
import { BASE_IMAGE_URL } from "../constants/index";
//import StackGrid from 'vue-stack-grid-component'
import {
  mapGetters,
} from 'vuex'

export default {
  name: 'Feed',

  components: {
    //StackGrid,
    //ArticleCard: () => import('@/components/ArticleCard'),
     PostCard: () => import('@/components/PostCard'),
     VideoCard: () => import('@/components/VideoCard'),
  },

  data: () => ({
    layout: [3, 3, 3, 3, 3, 3],
    baseImageUrl: BASE_IMAGE_URL,

  }),
  created() {  this.getVideos(); this.getArticles(); this.getPosts(); this.getNewsPosts(); },

  computed: {
    ...mapGetters(['loading', 'articles', 'videos','posts']),
  },

  methods: {
    getArticles() {
      this.$store.dispatch('getArticles', { 'page': this.page,'search': '' , 'tag': '','exceptTag':'' })
    },

    getVideos() {
      this.$store.dispatch("getVideos", { 'page': this.page,'search': '' , 'tag': '','exceptTag':''});
    },
    getPosts() {
      this.$store.dispatch("getPosts", {'page': this.page,'search': '' , 'tag': '','exceptTag':7 });
    },
    getNewsPosts() {
      this.$store.dispatch("getNewsPosts", {'page': this.page,'search': '' , 'tag': 7,'exceptTag':'' });
    },
  },
  watch: {

  },
}
</script>
